// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-classic-tsx": () => import("./../../../src/components/templates/classic.tsx" /* webpackChunkName: "component---src-components-templates-classic-tsx" */),
  "component---src-components-templates-homepage-tsx": () => import("./../../../src/components/templates/homepage.tsx" /* webpackChunkName: "component---src-components-templates-homepage-tsx" */),
  "component---src-components-templates-product-tsx": () => import("./../../../src/components/templates/product.tsx" /* webpackChunkName: "component---src-components-templates-product-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

